import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Navbar} from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"

import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module.js";

// Images

const HeaderLogo = (props) => {

  const [window_wdth, setWindowWidth] = useState(0)

  useEffect(()=>{
    setWindowWidth(typeof window !== 'undefined' ? window.innerWidth : 0)
  },[])
  

  const data = useStaticQuery(graphql`
  query HeaderLogoQueryTheme{
    glstrapi {

      siteConfig {
        Header_Logo {
          url
          alternativeText
          width
          height
        }
        Header_Bg_White_Logo{
          url
          alternativeText
          width
          height
        }
        Collection_Logo {
          alternativeText
          url
          width
          height
        }
      }
    }
  }`)

  var header_data = data.glstrapi;

  //console.log("menu_data", props?.GQLPage?.menu)

  let header_logo_1 = header_data?.siteConfig?.Collection_Logo ? header_data?.siteConfig?.Collection_Logo : header_data?.siteConfig?.Header_Logo;

  let header_logo_2 = header_data?.siteConfig?.Header_Bg_White_Logo ? header_data?.siteConfig?.Header_Bg_White_Logo : header_data?.siteConfig?.Header_Logo;

  //console.log("window_wdth ==>", window_wdth, props?.GQLPage?.layout)

  return (
    <React.Fragment>
      <Navbar.Brand>
          <Link to="/">
            {(props.scroll && props?.GQLPage?.layout !== "collection_page_template") ?
              <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo" width={header_data?.siteConfig?.Header_Logo.width} height={header_data?.siteConfig?.Header_Logo.height}/>
            :            
            (props?.GQLPage?.layout === "landing_template" || props?.GQLPage?.layout === "static_template" || props?.GQLPage?.layout === "team_template") ? (
              props?.GQLPage?.menu?.parent?.slug === "johnshepherd-collection" ?
                <ImageModule ImageSrc={header_logo_1} altText={"Logo"} className="logo" width={header_logo_1.width} height={header_logo_1.height}/>
              :
                props?.GQLPage?.menu?.slug === "student-hub" ?
                <ImageModule ImageSrc={header_data?.siteConfig?.student_hub_logo ? header_data?.siteConfig?.student_hub_logo : header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo"/>
                :
                window_wdth > 1199 ?                
                <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} altText={"Logo"} className="logo 1" width={header_data?.siteConfig?.Header_Logo.width} height={header_data?.siteConfig?.Header_Logo.height}/>
                :
                <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} width={header_logo_2.width} height={header_logo_2.height} altText={"Logo"} className="logo 2"/>
              )
            :
            props?.GQLPage?.layout === "collection_page_template" ?
              <ImageModule ImageSrc={header_logo_1} width={header_logo_1.width} height={header_logo_1.height} altText={"Logo"} className="logo 3"/>
              :
                window_wdth > 1199 ?                
                <ImageModule ImageSrc={header_logo_2} altText={"Logo"} className="logo 4" width={header_data?.siteConfig?.Header_Logo.width} height={header_data?.siteConfig?.Header_Logo.height}/>
                :
                <ImageModule ImageSrc={header_data?.siteConfig?.Header_Logo} width={header_logo_2.width} height={header_logo_2.height} altText={"Logo"} className="logo 5"/>
            }                      
          </Link>
      </Navbar.Brand>
    </React.Fragment>
  )
}



export default HeaderLogo